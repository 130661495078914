<template>
  <main-layout
    :key="projectId"
    :entity-name="name"
    :entity-logo="logo"
    :parent-entity-name="isUserProjectStaff ? projectName : null"
    :parent-entity-route="isUserProjectStaff ? { name: 'competition-clubs', params: { organizationId, projectId } } : null"
    :sidebar-items="filteredSidebarItems"
  >
    <router-view :key="(`${$route.params.teamId}-${$route.params.cohortId}-${$route.params.serviceId}` || '')" />
    <!-- Dialogs -->
    <create-team-dialog
      v-if="showCreateTeamDialog"
      :key="clubId"
      :organization-id="organizationId"
      :project-id="projectId"
      :club-id="clubId"
      @close-dialog="showCreateTeamDialog = false"
    />
  </main-layout>
</template>
<script>
import { mapGetters } from 'vuex'
import MainLayout from '@/components/MainLayout'

export default {
  name: 'Club',
  components: {
    MainLayout,
    CreateTeamDialog: () => import('@/modules/club/CreateTeamDialog'),
  },
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
    clubId: { type: String, required: true },
  },
  data() {
    return {
      isDataLoaded: false,
      showCreateTeamDialog: false,
    }
  },
  computed: {
    ...mapGetters({ organization: 'organization/data' }),
    ...mapGetters({ project: 'project/data' }),
    ...mapGetters({ club: 'club/data' }),
    ...mapGetters({ teams: 'club/teams' }),
    ...mapGetters({ isUserProjectStaff: 'project/isUserStaff' }),
    ...mapGetters({ isUserManager: 'project/isUserManager' }),
    ...mapGetters({ services: 'project/services' }),
    ...mapGetters({ isClubCollaborator: 'club/isClubCollaborator' }),
    ...mapGetters({ sidebarItems: 'club/sidebarItems' }),
    ...mapGetters('competition', ['cohorts']),
    ...mapGetters('project', ['hasDisableTeamEditByClubFeature', 'isUserSubmanager']),
    name({ club }) { return club.name },
    logo({ club }) { return club.logo || '' },
    projectName({ project }) { return project.name },
    filteredSidebarItems({ sidebarItems, teamItems, addTeamItem, tripsItems, hasDisableTeamEditByClubFeature, isUserSubmanager }) {
      return [...sidebarItems, ...teamItems,
        ...(!hasDisableTeamEditByClubFeature || isUserSubmanager ? [addTeamItem] : []), ...tripsItems,
      ].filter(item => item.id === 'trips' ? tripsItems.length : true)
    },
    tripsItems({ services }) {
      return services.filter(service => service.isActive && service.platform === 'web').map(service => ({
        id: service.url,
        name: service.name,
        route: {
          name: 'club-tripService',
          params: { serviceId: service.id },
        },
        parentId: 'trips',
        conditions: 'all',
      }))
    },
    teamItems({ teams }) {
      return teams.map(team => ({
        id: team.id,
        icon: team.isApproved ? 'check_circle_outlined' : null,
        iconColor: '#89D184',
        name: team.nameWithCohort,
        route: {
          name: 'club-team',
          params: { teamId: team.id, cohortId: team.cohortId },
        },
        parentId: 'teams',
        conditions: 'all',
      })).sort((a, b) => a?.name?.localeCompare(b?.name))
    },
    addTeamItem({ isUserManager, isClubCollaborator }) {
      return {
        id: 'create-team-dialog',
        type: 'button',
        icon: 'add_box',
        text: 'club.actions.addTeam',
        disabled: !(isUserManager || isClubCollaborator),
        action: () => { this.showCreateTeamDialog = true },
        button: { type: 'text' },
        parentId: 'teams',
        conditions: 'all',
      }
    },
  },
  async created() {
    const { organizationId, projectId } = this.$route.params
    if (!this.$store.state.competition.dbCohorts.length) await this.$store.dispatch('competition/bindCohorts', { organizationId, projectId })
    if (!this.$store.state.competition.dbRegions.length) await this.$store.dispatch('competition/bindRegions', { organizationId, projectId })
    if (!this.$store.state.competition.dbCategories.length) await this.$store.dispatch('competition/bindCategories', { organizationId, projectId })
  },
}
</script>
